.sidebar .sidebar-fixed.sidebar-wrap,
.sidebar-wrap.sidebar.sidebar-fixed:not(.sidebar-end) {
  top: 5.4rem;
  margin-left: 0;
}

.defaultLayout-main.dashboard-unfoldable {
  margin-left: 64px;
}

.defaultLayout-main {
  display: flex;
  flex-direction: column;
  margin-left: 240px;

  &-main {
    flex: 1;
    display: flex;
  }

}

.layout {
  margin-top: 0.5rem;
}

@media (max-width: 767.98px) {
  .defaultLayout-main.dashboard-unfoldable {
      margin-left: 40px;
  }
  .dashboard button.date-picker-toggle {
     margin-bottom: 10px;
  }
  .dashboard .select-farm .select-farm__control , .select-farm .select-farm__control {
      min-width: auto;
    }
  .sidebar-wrap.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover), .sidebar-wrap.sidebar-fixed.sidebar-narrow {
    z-index: 1031;
    width: 4rem;
  }
    .milk-history .date-list button {
      width: 25%;
    }
}

.session.modal.show {
  .modal-dialog {
    max-width: 500px;
  }

  .session-header {
    svg {
      margin: -5px 16px 0px 0px;
      color: #1a74ea;
    }
  }

  .modal-content .modal-footer button {
    padding: 4px 12px;
  }
}

.select-farm {
  .select-farm__control {
    padding: 5px 10px;
    min-width: 300px;
    font-family: Roboto;
    font-size: 20px;
  }

  .select-farm__menu {
    margin-top: 0px;

  }
}

.select-farm__menu-portal {
  .select-farm__option {
    font-family: Roboto;
    font-size: 20px;
  }
}

// .listing {
//   flex: 1;
// }