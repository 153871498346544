.disable-btn{
  // background: #cdd0d4 !important;
  // border-color: #cdd0d4 !important;
  // color: #fff !important;
  box-shadow: none !important;
  cursor: not-allowed;
  span{
      // background: transparent;
  } 
} 
.default-btn, button { 
  .spinner-border{
    width: 1rem;
    height: 1rem;
    margin-right: 6px;
  }
}
.outer-text{
  text-align: center;
  padding: 5px 0px 0px;
}