.navItem {
  &:hover {
    background-color: #c4c4c4 !important;
  }
}

.sidebar {
  .sidebar-toggle {
    background: var(--cui-sidebar-bg);
    padding: 5px 0px;
    border-top: none;
  }

  .sidebar-toggler::before {
    background-image: var(--cui-sidebar-toggler-indicator, url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 11 14%27%3E%3Cpath fill=%27rgba%2816, 110, 234, 0.87%29%27 d=%27M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z%27/%3E%3C/svg%3E"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem;
  }
}

.sidebar-nav {

  // .nav-item:first-child:not(.nav-group-items .nav-item){background: #C4C4C4;padding: 25px 0px;border-top: none;}
  .nav-group,
  .nav-item:not(.nav-group-items .nav-item) {
    border-top: 2px solid rgb(241 245 249 / 20%);
  }

  .nav-group.show .nav-group-toggle {
    font-weight: 700;
  }

  .nav-link {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    padding: 0.970rem 1rem;

    &.active {
      font-weight: 700;
      color: var(--cui-white);
    }
  }

  .nav-icon {
    height: 1.35rem;
  }

  .nav-group-toggle::after {
    // background-image: var(--cui-sidebar-nav-group-indicator, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%2820, 0, 0, 0.6%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"));
    background-size: 0.99rem;
  }

  .nav-link:hover.nav-group-toggle::after {
    // background-image: var(--cui-sidebar-nav-group-indicator-hover, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%2816, 110, 234, 0.87%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"));
    background-size: 0.99rem;
  }
}
@media (max-width: 767.98px){
  .sidebar-toggler{
    display: block;
  }
}
@media (min-width: 768px) {
  .sidebar-wrap.sidebar-fixed.sidebar-narrow {
    z-index: 1031;
    width: 4rem;
  }
  .sidebar-wrap.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler,
  .sidebar-wrap.sidebar-narrow .sidebar-toggler {
    position: initial;
    top: initial;
  }

}