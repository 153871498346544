// COLOR VARIABLES
:root{
    --color-background: #23304C;
    --color-white: white;
    --color-grey-white: #FAFAFA;
    --color-grey: #E5E5E5;
    --color-grey-blue: rgba(133, 159, 193, 1);
    --color-grey-blue-dark:rgba(76, 85, 101, 0.85);
    --color-grey-dark: #444444;
    --color-blue: hsl(212, 82%, 54%);
    --color-blue-hover: hsl(212, 100%, 65%);
    --cui-normal-text-color: #333333;
    --cui-header-bg: #E5E5E5;
    --cui-header-nav-bg: #034EA2;
    --cui-sidebar-bg:#0C3A72;
    --cui-sidebar-nav-link-active-bg:#034EA2;
    --cui-sidebar-brand-color: #333333;
    --cui-sidebar-brand-bg:#E5E5E5;
    --cui-input-group-addon-bg:transparent;
    --cui-header-color:#333333;
    --cui-sidebar-toggler-bg:#E5E5E5; 
    --cui-sidebar-nav-link-active-color:#fff;
    --cui-sidebar-nav-link-hover-color:#fff;
    --cui-sidebar-brand-bg:#C4C4C4;
    --cui-light-rgb:#fff;
    --cui-sidebar-nav-group-bg: --cui-sidebar-bg;
    --cui-sidebar-nav-link-color:#fff;
    --cui-sidebar-nav-group-toggle-show-color:#fff;
    --cui-normal-text-light-grey-color:#B3B3B3;
    --color-light-grey-d8:#D8D8D8;
    --cui-input-disabled-bg:#F4F4F4;
    --cui-input-disabled-border-color:#E5E5E5; 
    --cui-text-light-grey-808080:#808080;
    --cui-text-light-red:#EC1C24; 
    --color-black:#000;
    ----bg-color-sidebar-blue:#0C3A72;
    --bg-color-blue:#034EA2; 


      --cui-blue: #0d6efd;
      --cui-indigo: #6610f2;
      --cui-purple: #6f42c1;
      --cui-pink: #d63384;
      --cui-red: #dc3545;
      --cui-orange: #fd7e14;
      --cui-yellow: #ffc107;
      --cui-green: #198754;
      --cui-teal: #20c997;
      --cui-cyan: #0dcaf0;
      --cui-white: #fff;
      --cui-gray: #8a93a2;
      --cui-gray-dark: #636f83;
      --cui-gray-100: #ebedef;
      --cui-gray-200: #d8dbe0;
      --cui-gray-300: #c4c9d0;
      --cui-gray-400: #b1b7c1;
      --cui-gray-500: #9da5b1;
      --cui-gray-600: #8a93a2;
      --cui-gray-700: #768192;
      --cui-gray-800: #636f83;
      --cui-gray-900: #4f5d73;
      --cui-primary: #321fdb;
      --cui-secondary: #9da5b1;
      --cui-success: #2eb85c;
      --cui-info: #39f;
      --cui-warning: #f9b115;
      --cui-danger: #e55353;
      --cui-light: #ebedef;
      --cui-dark: #4f5d73;
      --cui-primary-rgb: 50, 31, 219;
      --cui-secondary-rgb: 157, 165, 177;
      --cui-success-rgb: 46, 184, 92;
      --cui-info-rgb: 51, 153, 255;
      --cui-warning-rgb: 249, 177, 21;
      --cui-danger-rgb: 229, 83, 83;
      --cui-light-rgb: 235, 237, 239;
      --cui-dark-rgb: 79, 93, 115;
      --cui-white-rgb: 255, 255, 255;
      --cui-black-rgb: 0, 0, 21;
      --cui-body-color-rgb: 44, 56, 74;
      --cui-body-bg-rgb: 255, 255, 255;
      --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      --cui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
      --cui-body-font-family: var(--cui-font-sans-serif);
      --cui-body-font-size: 1rem;
      --cui-body-font-weight: 400;
      --cui-body-line-height: 1.5;
      --cui-body-color: rgba(44, 56, 74, 0.95);
      --cui-body-bg: #fff;
}
button.btn-primary {
  --cui-btn-bg: #034EA2;
  --cui-btn-border-color: #034EA2;
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #034EA2;
  --cui-btn-hover-border-color: #034EA2;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #034EA2;
  --cui-btn-active-border-color: #034EA2;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #034EA2;
  --cui-btn-disabled-border-color: #034EA2;
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-shadow: rgba(50, 31, 219, 0.5);
} 
button.btn-success{
  --cui-btn-bg: #2CAC4C;
  --cui-btn-border-color: #2CAC4C;
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #2CAC4C;
  --cui-btn-hover-border-color: #2CAC4C;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #2CAC4C;
  --cui-btn-active-border-color: #2CAC4C;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #2CAC4C;
  --cui-btn-disabled-border-color: #2CAC4C;
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-shadow: rgba(44, 172, 76, 0.5); 
}
$theme-colors: (
  "primary": #E5E5E5,
  // "transparent":rgba(0, 0, 0, 0),
);