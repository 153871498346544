.toast-modal {
    .btn.btn-close {
        width: 2rem;
        height: 2rem;
        background-size: 2rem;
    }
}

.toast-modal.success {
    .toast {
        --cui-bg-opacity: 1;
        border-color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity));
        background-color: #fff;

        .toast-body,
        strong {
            --cui-bg-opacity: 1;
            color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity));
        }

        .toast-body {
            font-size: 16px;
        }

        strong {
            font-size: 18px;
        }
    }
}

.error {
    .toast {
        border: solid 1px #dc3545;
        color: #dc3545;
        .toast-header{
            color: #dc3545;
        }
    }
}