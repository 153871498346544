.page-loader {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    z-index: 1050;//9999;
    background: #00000030;
    height: 100vh;
    top: 0;

    .spinner-border {
        color: #0d6efd !important;
        width: 3rem;
        height: 3rem;
    }
}

.list-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    z-index: 1050;//9999;
    min-height: 500px;
    top: 0;
    height: 100%;

    .spinner-border {
        color: #0d6efd !important;
        width: 3rem;
        height: 3rem;
    }
}


.list-load {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;

    .snippet {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dot-elastic {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #106eea;
        color: #106eea;
        animation: dotElastic 1s infinite linear;
    }

    .dot-elastic::before,
    .dot-elastic::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    .dot-elastic::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #106eea;
        color: #106eea;
        animation: dotElasticBefore 1s infinite linear;
    }

    .dot-elastic::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #106eea;
        color: #106eea;
        animation: dotElasticAfter 1s infinite linear;
    }

    @keyframes dotElasticBefore {
        0% {
            transform: scale(1, 1);
        }

        25% {
            transform: scale(1, 1.5);
        }

        50% {
            transform: scale(1, 0.67);
        }

        75% {
            transform: scale(1, 1);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    @keyframes dotElastic {
        0% {
            transform: scale(1, 1);
        }

        25% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1, 1.5);
        }

        75% {
            transform: scale(1, 1);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    @keyframes dotElasticAfter {
        0% {
            transform: scale(1, 1);
        }

        25% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1, 0.67);
        }

        75% {
            transform: scale(1, 1.5);
        }

        100% {
            transform: scale(1, 1);
        }
    }

}