@import "./abstract/variables";
@import "./base/reset";
@import "./base/typography";

body {
  background-color: #5d96ee;
}

// svg{
//     height: 2rem;
//     width: 2rem;
// }

@import "@coreui/coreui/scss/coreui";

.shimmer {
  background: #eaeff3;
  border-radius: 2px;
  position: relative;
}

.shimmer:after {
  -webkit-animation: slide 0.75s infinite;
  animation: slide 0.75s infinite;
  background: linear-gradient(90deg, #0000000a, rgba(255, 255, 255, 0.4), transparent);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 60%;
  z-index: 1;
}

@-webkit-keyframes slide {
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slide {
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

body {
  ::-webkit-scrollbar {
    width: 10px;
    /*it's not helping with showing the thumb border-left*/
    overflow: auto;
  }

  ::-webkit-scrollbar-track {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.8));
    overflow: auto;
    -webkit-box-shadow: inset 0 0 6px #5d96ee;
    border-radius: 10px;
    border-left: 3px solid white;
    border-right: 3px solid white;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(36, 124, 240, 0.7);
    border-radius: 3px;

    /*doesnt work (the goal is making thumb thinner)*/
    border: rgba(36, 124, 240, 0.7) 5px solid;
    overflow: auto;
  }

  ::-webkit-scrollbar-thumb:hover {
    /*doesnt work (the goal is make it bigger than scrollbar itself)*/
    transform: scale(1.5);
    border-left: 10px rgba(36, 124, 240, 0.7) solid;
  }
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.invalid-form-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353;
}

.bg-success {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity)) !important;
}

.bg-info {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-info-rgb), var(--cui-bg-opacity)) !important;
}

.bg-warning {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-warning-rgb), var(--cui-bg-opacity)) !important;
}

.bg-dark {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-dark-rgb), var(--cui-bg-opacity)) !important;
}

.bg-danger {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-danger-rgb), var(--cui-bg-opacity)) !important;
}

.btn.btn-close {
  background: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='%23D8D8D8'/%3E%3Cpath d='M29.898 11L22 18.898L14.102 11L11 14.102L18.898 22L11 29.898L14.102 33L22 25.102L29.898 33L33 29.898L25.102 22L33 14.102L29.898 11Z' fill='%23808080'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.7rem 0.7rem 0.7rem 0;
  border-right-color: #E6E6E6;    top: -6px;
}
.tooltip-inner { 
  padding: 0.45rem 0.9rem;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  background-color: var(--cui-white, #fff); 
  border: 2px solid #E6E6E6;
}.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 6px;
}
.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.8rem;
}