.dropdown {
  &-menu {
    width: 235px;
    margin-top: 14px;
  }

  &-item {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 15px;
  }
}

.header {
  &-sticky {
    background: var(--cui-header-nav-bg, #fff);
  }
}

.header-nav {
  .nav-link.user_name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: var(--cui-white);
  }

  .sidebar-toggle {
    background: transparent; //var(--cui-sidebar-bg);
    padding: 0px 0px;
    border-top: none;

    &::before {
      background-size: 1.2rem;
    }
  }
}

.header-brand {
  .brand-logo {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    padding-left: 48%;

    span {
      color: #106eea;
    }
  }
  img {
    width: 59px;
    top: 8px;
    position: absolute;
    padding-left: 8px;
  }
}

.bg_transparent input,
.bg_transparent input:active,
.bg_transparent input:focus,
.bg_transparent input:hover {
  background: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--cui-text-light-grey-808080);
}

.user-bg {
  background: #0c3a72;
  border-radius: 4px;
}
